
export const noAnonymizedInput = value => !value?.match(/\*\*/)

export const validateSSN = (value) => {
  if (!value) {
    return true
  }

  return value?.length === 12 && ['19', '20'].includes(value.slice(0, 2))
}

export function validateEach (property, object, validation) {
  return {
    [property]: new Array(object[property].length).fill(validation)
  }
}

export const phoneNumberValidator = (countryCode, number) => {
  if (!countryCode || !number) return false

  let cleanedNumber = number.replace(/[-\s]/g, '')

  if (cleanedNumber.startsWith('0')) {
    cleanedNumber = cleanedNumber.substring(1)
  }

  const swedishPhoneNumberRegex = /^(\+46|46)?\d{7,9}$/
  const norwegianPhoneNumberRegex = /^(\+47|47)?\d{7,8}$/

  if (countryCode === 46) {
    if (cleanedNumber.startsWith('7')) {
      return cleanedNumber.length === 9
    } else {
      return swedishPhoneNumberRegex.test(cleanedNumber)
    }
  } else if (countryCode === 47) {
    return norwegianPhoneNumberRegex.test(cleanedNumber)
  } else {
    return cleanedNumber.length >= 5
  }
}
